<template>
  <v-container>
    <CreateManagerDialog v-bind:open-dialog="dialog" v-on:close-dialog="dialog=false" :agencyId="agencyId"
                         v-on:save-manager="saveManager($event)"></CreateManagerDialog>
    <ManualOrderCodeDialog v-bind:open-dialog="codeDialog" v-on:close-dialog="codeDialog=false" :orderCode="orderCode"
                           :prefix="agency.prefix"
                           v-on:change-code="changeCode($event)"></ManualOrderCodeDialog>

    <v-stepper v-model="e6" vertical>
      <validation-observer ref="observer" v-slot="{ invalid }">
        <v-form>
          <v-stepper-step :complete="e6 > e6-1" step="1">
            {{$t('create-order.main-data')}}
          </v-stepper-step>

          <v-stepper-content step="1">
            <v-card outlined>
              <v-card-text>
                <v-chip label color="primary" text-color="white">
                  <v-icon left> mdi-folder-information-outline</v-icon>
                  {{$t('create-order.main')}}
                </v-chip>
                <v-row class="ma-auto">
                  <v-col cols="12" sm="4" lg="4">
                    <validation-provider rules="required" v-slot="{ errors }">
                      <v-autocomplete :items="activeAgencies" :label="$t('create-order.agency')" item-text="agencyName"
                                      clearable item-value="agencyId" v-on:change="getAgencyById" v-model="agencyId">
                      </v-autocomplete>
                      <span class="red--text font-weight-light">{{ errors[0] }}</span>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="4" lg="4">
                    <validation-provider rules="required" v-slot="{ errors }">
                      <v-autocomplete v-model="langPairIds" :items="langPairs" :label="$t('create-order.language')"
                                      required
                                      item-text="fullPairName"
                                      item-value="langPairId" multiple clearable>
                      </v-autocomplete>
                      <span class="red--text font-weight-light">{{ errors[0] }}</span>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="4" lg="4">
                    <v-text-field v-model="incomingNumber" :label="$t('create-order.incoming-number')" clearable
                                  :hint="$t('create-order.enter-order-number-hint')"/>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <StepperContinueButton v-on:next-step="e6=++e6" :disabled="invalid"></StepperContinueButton>
          </v-stepper-content>
        </v-form>
      </validation-observer>


      <validation-observer ref="observer" v-slot="{ invalid }">
        <v-form>
          <v-stepper-step
            :complete="e6 > 2"
            step="2">
            {{$t('create-order.describe-order-details')}}
          </v-stepper-step>

          <v-stepper-content step="2">
            <v-card outlined>
              <v-card-text>
                <v-chip label color="primary" text-color="white">
                  <v-icon left>
                    mdi-card-text-outline
                  </v-icon>
                  {{$t('create-order.detailed-description')}}
                </v-chip>
                <validation-provider rules="required" v-slot="{ errors }">
                  <v-textarea v-model="description" class="purple-input"
                              :label="$t('create-order.order-description')" clearable counter rows="3"/>
                  <span class="red--text font-weight-light">{{ errors[0] }}</span>
                </validation-provider>
              </v-card-text>
            </v-card>

            <v-row>
              <v-col sm="6" md="6">
                <StepperBackButton v-on:back-step="e6=--e6"></StepperBackButton>
              </v-col>
              <v-col sm="6" md="6">
                <StepperContinueButton v-on:next-step="e6=++e6" :disabled="invalid"></StepperContinueButton>
              </v-col>
            </v-row>

          </v-stepper-content>
        </v-form>
      </validation-observer>

      <validation-observer ref="observer" v-slot="{ invalid }">
        <v-form>
          <v-stepper-step :complete="e6 > 3" step="3">
            {{$t('create-order.define-dates')}}
          </v-stepper-step>

          <v-stepper-content step="3">
            <v-card outlined>
              <v-card-text>
                <v-chip label color="primary" text-color="white">
                  <v-icon left>
                    mdi-calendar-range
                  </v-icon>
                  {{$t('create-order.dates')}}
                </v-chip>
                <v-row>

                  <v-col cols="12" md="6">
                    <validation-provider rules="required" v-slot="{ errors }">
                      <v-datetime-picker v-model="orderDate" :label="$t('create-order.order-date')"
                                         :timePickerProps="timePickerProps"
                                         :date-picker-props="datePickerProps" :text-field-props="textFieldProps">
                      </v-datetime-picker>
                      <span class="red--text font-weight-light">{{ errors[0] }}</span>
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" md="6">
                    <validation-provider rules="required" v-slot="{ errors }">
                      <v-datetime-picker v-model="finishTime" :label="$t('create-order.finish-time')"
                                         :timePickerProps="timePickerProps"
                                         :date-picker-props="datePickerProps" :text-field-props="textFieldProps">
                      </v-datetime-picker>
                      <span class="red--text font-weight-light">{{ errors[0] }}</span>
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-alert v-if="finishTime!=='' && orderDate>finishTime" dense outlined border="left" text type="error">
              <strong>{{$t('create-order.warning')}}: </strong>{{$t('create-order.dates-warning-text')}}
            </v-alert>
            <v-row>
              <v-col sm="6" md="6">
                <StepperBackButton v-on:back-step="e6=--e6"></StepperBackButton>
              </v-col>
              <v-col sm="6" md="6">
                <StepperContinueButton v-on:next-step="e6=++e6"
                                       :disabled="invalid || finishTime!=='' && orderDate>finishTime"></StepperContinueButton>
              </v-col>
            </v-row>
          </v-stepper-content>
        </v-form>
      </validation-observer>

      <v-stepper-step step="4" :complete="e6 > 4">
        {{$t('create-order.statistics')}}
      </v-stepper-step>
      <validation-observer ref="observer" v-slot="{ invalid }">
        <v-form>
          <v-stepper-content step="4">
            <v-card outlined>
              <v-card-text>
                <v-chip label color="primary" text-color="white">
                  <v-icon left>
                    mdi-finance
                  </v-icon>
                  {{$t('create-order.statistics')}}
                </v-chip>
                <v-row>
                  <v-col cols="12" md="3" sm="6">
                    <validation-provider :rules="{required:true,numeric:true}" v-slot="{ errors }">
                      <v-text-field
                        :label="$t('create-order.symbol-count')" v-model="symbolCount" v-on:input="recalculate"/>
                      <span class="red--text font-weight-light">{{ errors[0] }}</span>
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" md="3" sm="6">
                    <validation-provider :rules="{required:true,numeric:true}" v-slot="{ errors }">
                      <v-text-field
                        :label="$t('create-order.price-per-page')" v-model="pricePerPage" v-on:input="recalculate"
                        :readonly="readonly"/>
                      <span class="red--text font-weight-light">{{ errors[0] }}</span>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" md="3" sm="6">
                    <validation-provider rules="required" v-slot="{ errors }">
                      <v-text-field
                        :label="$t('create-order.page-count')" v-model="pageCount" v-on:input="recalculate"/>
                      <span class="red--text font-weight-light">{{ errors[0] }}</span>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" md="3" sm="6">
                    <validation-provider :rules="{required:true,numeric:true}" v-slot="{ errors }">
                      <v-text-field
                        :label="$t('create-order.total-cost')" v-model="totalCost" v-on:input="recalculate"
                        :readonly="readonly"/>
                      <span class="red--text font-weight-light">{{ errors[0] }}</span>
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-autocomplete :items="calculationTypes" :label="$t('create-order.calculation-type')" required
                                    item-text="calculationTypeName"
                                    item-value="calculationTypeId" v-model="calculationTypeId" :readonly="readonly"
                                    v-on:change="changeCalculation">
                    </v-autocomplete>
                  </v-col>

                  <v-col cols="12" md="2">
                    <v-switch inset v-on:change="isFreeOrderCheck"
                              v-model="freeOrder" :label="$t('create-order.free-order')">
                    </v-switch>
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-switch inset v-on:change="recalculate" :disabled="readonly"
                              v-model="userSettings.minOrderEnabled"
                              :label="userSettings.minOrderEnabled? $t('create-order.min-order-enabled') :$t('create-order.min-order-enabled')">
                    </v-switch>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-expand-x-transition>
                      <div v-if="wppIndex!==null">
                        <validation-provider :rules="{required:true,numeric:true}" v-slot="{ errors }">
                          <v-text-field :label="$t('create-order.word-count')" v-model="wordCount"
                                        v-on:input="recalculate"
                                        :readonly="readonly"/>
                          <span class="red--text font-weight-light">{{ errors[0] }}</span>
                        </validation-provider>
                      </div>
                    </v-expand-x-transition>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-row>
              <v-col sm="6" md="6">
                <StepperBackButton v-on:back-step="e6=--e6"></StepperBackButton>
              </v-col>
              <v-col sm="6" md="6">
                <StepperContinueButton v-on:next-step="e6=++e6" :disabled="invalid"></StepperContinueButton>
              </v-col>
            </v-row>
          </v-stepper-content>
        </v-form>
      </validation-observer>

      <v-stepper-step step="5" :complete="e6 > 5">
        {{$t('create-order.manager')}}
      </v-stepper-step>
      <validation-observer
        ref="observer"
        v-slot="{ invalid }">
        <v-form>
          <v-stepper-content step="5">
            <v-card outlined>
              <v-card-text>
                <v-chip label color="primary" text-color="white">
                  <v-icon left>
                    mdi-account-details
                  </v-icon>
                  {{$t('create-order.manager')}}
                </v-chip>
                <v-row>
                  <v-col cols="12" md="8" sm="8">
                    <validation-provider rules="required" v-slot="{ errors }">
                      <v-autocomplete v-model="managerId" :items="filteredManagers" :label="$t('create-order.manager')"
                                      required
                                      item-text="surname" item-value="managerId">
                      </v-autocomplete>
                      <span class="red--text font-weight-light">{{ errors[0] }}</span>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" md="4" sm="4">
                    <v-btn color="secondary" @click="dialog = true" v-if="agencyId!==''" block>
                      <v-icon left dark>
                        mdi-account-plus-outline
                      </v-icon>
                      {{$t('buttons.create-manager')}}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-row>
              <v-col sm="6" md="6">
                <StepperBackButton v-on:back-step="e6=--e6"></StepperBackButton>
              </v-col>
              <v-col sm="6" md="6">
                <StepperContinueButton v-on:next-step="e6=++e6" :disabled="invalid"></StepperContinueButton>
              </v-col>
            </v-row>
          </v-stepper-content>
        </v-form>
      </validation-observer>

      <v-stepper-step step="6" :complete="e6 > 6">
        {{$t('create-order.extras')}}
        <small>{{$t('create-order.additional-info')}}</small>
      </v-stepper-step>
      <validation-observer ref="observer" v-slot="{ invalid }">
        <v-form>
          <v-stepper-content step="6">
            <v-card outlined>
              <v-card-text>
                <v-chip label color="primary" text-color="white">
                  <v-icon left>
                    mdi-information-outline
                  </v-icon>
                  {{$t('create-order.extras')}}
                </v-chip>
                <v-row>
                  <v-col cols="12" md="3" sm="6">
                    <validation-provider rules="required" v-slot="{ errors }">
                      <v-autocomplete
                        v-model="orderTypeId" :items="orderTypes" :label="$t('create-order.order-type')" required
                        item-text="orderTypeName"
                        item-value="orderTypeId">
                      </v-autocomplete>
                      <span class="red--text font-weight-light">{{ errors[0] }}</span>
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" md="3" sm="6">
                    <validation-provider rules="required" v-slot="{ errors }">
                      <v-autocomplete v-model="topicIds" :items="topics" :label="$t('create-order.topic')" required
                                      item-text="topicName"
                                      item-value="topicId" multiple>
                      </v-autocomplete>
                      <span class="red--text font-weight-light">{{ errors[0] }}</span>
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" md="3" sm="6">
                    <v-text-field
                      v-model="customerName" :label="$t('create-order.customer')" clearable/>
                  </v-col>

                  <v-col cols="12" md="3" sm="6">
                    <v-autocomplete v-model="toolIds" :items="catTools" :label="$t('create-order.cat-tool')" required
                                    item-text="toolName"
                                    item-value="toolId" multiple>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="9" sm="9">
                    <v-textarea v-model="comments" class="purple-input" :label="$t('create-order.comments')" clearable
                                counter rows="2"/>
                  </v-col>
                  <v-col cols="12" md="3" sm="3">
                    <v-btn color="secondary" @click="codeDialog = true">
                      <v-icon left dark> mdi-qrcode-edit</v-icon>
                      {{$t('buttons.change-order-code')}}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <v-row>
              <v-col sm="6" md="6">
                <StepperBackButton v-on:back-step="e6=--e6"></StepperBackButton>
              </v-col>
              <v-col sm="6" md="6">
                <v-btn :loading="loading" color="primary" @click="createOrder" :disabled="invalid" block>
                  {{$t('buttons.create-order')}}
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>
        </v-form>
      </validation-observer>
    </v-stepper>
  </v-container>
</template>

<script>
  import {AXIOS} from "../../../http-commons";
  import StepperContinueButton from "../../components/buttons/StepperContinueButton";
  import StepperBackButton from "../../components/buttons/StepperBackButton";
  import CreateManagerDialog from "../managers/CreateManagerDialog";
  import ManualOrderCodeDialog from "./ManualOrderCodeDialog";

  import {ValidationObserver, ValidationProvider, extend} from 'vee-validate';
  import {required} from 'vee-validate/dist/rules';
  import {numeric} from 'vee-validate/dist/rules';
  import {useOrders} from "../../stores/ordersStore";
  import {useAgencies} from "../../stores/agenciesStore";
  import {useManagers} from "../../stores/managersStore";
  import {mapState} from "pinia";
  import {useUser} from "../../stores/userStore";

  const ordersStore = useOrders()
  const agencyStore = useAgencies()
  const managersStore = useManagers()

  extend('required', {
    ...required,
    message: 'The field is required'
  });
  extend('numeric', {
    ...numeric,
    message: 'Only numbers are allowed'
  });

  let moment = require('moment');

  export default {
    name: "Create Order",
    components: {
      ValidationProvider,
      ValidationObserver,
      CreateManagerDialog,
      StepperBackButton,
      StepperContinueButton,
      ManualOrderCodeDialog
    },
    data() {
      return {
        activeAgencies: [],
        orderCode: '',
        codeDialog: false,
        filteredManagers: [],
        sppIndex: null,
        wppIndex: null,
        fpIndex: null,
        readonly: false,
        freeOrder: false,
        agency: [],
        loading: false,
        statusId: 1,
        dialog: false,
        comments: '',
        description: '',
        customerName: '',
        managerId: '',
        payerId: '',
        orderTypeId: '',
        finishTime: '',
        agencyId: '',
        langPairIds: [],
        topicIds: [],
        calculationTypeId: '',
        calculationTypes: [],
        toolIds: [],
        incomingNumber: '',
        e6: 1,
        langPairs: [],
        orderTypes: [],
        topics: [],
        catTools: [],
        modal: false,
        pageCount: null,
        symbolCount: null,
        wordCount: null,
        pricePerPage: null,
        totalCost: null,
        orderDate: new Date(),
        timePickerProps: {
          format: "24hr"
        },
        datePickerProps: {
          firstDayOfWeek: 1
        },
        textFieldProps: {
          prependIcon: 'mdi-clock-time-four-outline'
        },
      }
    },
    methods: {
      async updateNeededCheck() {
        console.log('agencies:', this.agencies)
        if (this.agencies.length) {
          this.activeAgencies = this.agencies.filter(e => e.active === true);
        }
        if (!this.agencies.length) {
          let res = await agencyStore.getAgenciesAsync()
          this.activeAgencies = res.data.filter(e => e.active === true);
        }
        if (!this.managers.length) {
          managersStore.populateManagers()
        }
      },
      isFreeOrderCheck() {
        if (this.freeOrder) {
          this.pricePerPage = 0;
          this.totalCost = 0;
          this.readonly = true;
        }
        if (!this.freeOrder) {
          this.pricePerPage = null;
          this.readonly = false;
        }
      },
      changeCode(newCode) {
        let action = 'codeChange'
        let objectType = 'Order code'
        this.orderCode = this.agency.prefix + newCode;
        this.userNotification(this.orderCode, action, objectType);
        this.codeDialog = false;
      },
      changeCalculation() {
        if (this.calculationTypes.length > 1) {
          let calcType = this.calculationTypes.filter(e => e.calculationTypeId === this.calculationTypeId);
          console.log('calctype' + calcType)
          if (calcType[0].perSymbol) {
            console.log('isPerSymbol:' + calcType[0].perSymbol)
            this.sppIndex = calcType[0].symbolsPerPage;
            this.wppIndex = null;
            this.fpIndex = null;
          }
          if (calcType[0].perWord) {
            console.log('isPerWord:' + calcType[0].perWord)
            this.wppIndex = calcType[0].wordsPerPage;
            this.sppIndex = null;
            this.fpIndex = null;
          }
          if (calcType[0].fixedPrice) {
            console.log('isFixedPrice:' + calcType[0].fixedPrice)
            this.fpIndex = calcType[0].fixedPriceValue;
            this.wppIndex = null;
            this.sppIndex = null;
          }
          this.recalculate(calcType[0]);
          console.log('spp:' + this.sppIndex)
          console.log('wpp:' + this.wppIndex)
          console.log('fp:' + this.fpIndex)
        }
      },
      recalculate(calcType) {
        console.log('SPP:',this.sppIndex)
        console.log('WPP:',this.wppIndex)
        console.log('FP:',this.fpIndex)
        if (this.sppIndex !== null) {
          console.log('case1')
          this.pageCount = (this.symbolCount / this.sppIndex).toFixed(2);
          this.totalCost = (this.pricePerPage * this.pageCount).toFixed(0);
          if (this.userSettings.minOrderEnabled) {
            if (this.symbolCount > 0 && this.pageCount < 1) {
              this.totalCost = this.pricePerPage;
            }
          }
        }
        if (this.wppIndex !== null) {
          console.log('case2')
          this.pageCount = (this.wordCount / this.wppIndex).toFixed(2);
          this.totalCost = (this.pricePerPage * this.pageCount).toFixed(0);
          if (this.userSettings.minOrderEnabled) {
            if (this.symbolCount > 0 && this.pageCount < 1) {
              this.totalCost = this.pricePerPage;
            }
          }
        }
        if (this.fpIndex !== null) {
          console.log('case3')
          this.minOrderEnabled = false;
          this.pageCount = (this.symbolCount / 1800).toFixed(2);
          this.totalCost = calcType.fixedPriceValue;
          this.pricePerPage = 0;
        }
        if (this.wppIndex === null && this.sppIndex === null && this.fpIndex === null) {
          console.log('case4')
          this.pageCount = (this.symbolCount / 1800).toFixed(2);
          this.totalCost = (this.pricePerPage * this.pageCount).toFixed(0);
          if (this.userSettings.minOrderEnabled) {
            if (this.symbolCount > 0 && this.pageCount < 1) {
              this.totalCost = this.pricePerPage * 1;
            }
          }
        }
      },
      getAgencyById() {
        this.agency = this.agencies.filter(e => e.agencyId === this.agencyId)[0];
        this.generateOrderCode(this.agency.prefix);
        if (this.agency.calculationType) {
          this.calculationTypeId = this.agency.calculationType.calculationTypeId;
          this.changeCalculation();
        }
      },

      dependentLists(agencyId) {
        this.filteredManagers = this.managers.filter(e => e.agency.agencyId === agencyId);
      },
      generateOrderCode(prefix) {
        let min = 10000;
        let max = 99999;
        let rand = (min + Math.random() * (max + 1 - min)).toFixed(0);
        this.orderCode = prefix + rand;
        this.dependentLists(this.agencyId);
      },
      saveManager(manager) {
        let action = 'create'
        let objectType = 'Manager'
        let params = new URLSearchParams();
        params.append('agencyId', this.agencyId);
        params.append('surname', manager.surname);
        params.append('email', manager.email);
        params.append('mobilePhone', manager.mobile);
        params.append('comment', manager.comment);
        params.append('position', manager.position);
        params.append('userId', this.currentUser.id);
        AXIOS.post(`/api/createManager`, params)
          .then(response => {
            managersStore.addManager(response.data)
            this.dependentLists(this.agencyId);
            this.dialog = false;
            this.userNotification(response.data, action, objectType);
            this.managerId = response.data.managerId;
          });
        manager = null;
      },
      createOrder() {
        this.loading = true;
        let action = 'create'
        let objectType = 'Order'
        let format = "YYYY-MM-DDTHH:mm:ss";
        this.orderDate = moment(this.orderDate).format(format);
        this.finishTime = moment(this.finishTime).format(format);

        let params = new URLSearchParams();
        params.append('orderCode', this.orderCode);
        params.append('agencyId', this.agencyId);
        params.append('userId', this.currentUser.id);
        params.append('description', this.description);
        params.append('topics', this.topicIds);
        params.append('pricePerPage', this.pricePerPage);
        params.append('pageCount', this.pageCount);
        params.append('symbolCount', this.symbolCount);
        if (this.wordCount !== null) {
          params.append('wordCount', this.wordCount);
        }
        params.append('totalCost', this.totalCost);
        params.append('tools', this.toolIds);
        params.append('managerId', this.managerId);
        params.append('incomingNumber', this.incomingNumber);
        params.append('statusId', this.statusId);
        params.append('comments', this.comments);
        params.append('customerName', this.customerName);
        params.append('orderTypeId', this.orderTypeId);
        params.append('payerId', '');
        params.append('langPairs', this.langPairIds);
        params.append('orderDate', this.orderDate);
        params.append('paymentDate', '');
        params.append('finishTime', this.finishTime);
        if (this.calculationTypeId !== null) {
          params.append('calculationTypeId', this.calculationTypeId);
        }
        AXIOS.post(`/api/createOrder`, params)
          .then(response => {
            this.order = response.data;
            ordersStore.addOrder(this.order)
            this.userNotification(response.data.orderCode, action, objectType);
            this.$router.push({path: '/order/' + response.data.orderId})
          })
        this.loading = false;
      },
    },
    computed: {
      ...mapState(useAgencies, ['agencies']),
      ...mapState(useManagers, ['managers']),
      ...mapState(useUser, ['userSettings']),
    },
    created() {
      this.updateNeededCheck();
      this.getActiveLanguages();
      this.getActiveTopics();
      this.getActiveOrderTypes();
      this.getActiveCatTools();
      this.getActiveCalculationTypes();
    },
  }
</script>

<style scoped>
  .red--text {
    font-size: 12px;
  }
</style>
