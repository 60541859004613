<template>
  <v-row justify="center">
    <v-dialog v-model="openDialog" persistent max-width="300px">
      <v-card>
        <validation-observer ref="observer" v-slot="{ invalid }">
          <v-container>
            <v-img :src="require(`@/assets/UI-images/${imgName}`)" height="100px">
            </v-img>
            <v-card-title>
              <span class="headline">{{orderCode}}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <validation-provider :rules="{required:true,numeric:true,length:5}" v-slot="{ errors }">
                      <v-text-field v-model="newCode" :label="$t('create-order.order-code')" clearable :prefix="prefix"/>
                      <span class="red--text font-weight-light">{{ errors[0] }}</span>
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text v-on:click="$emit('close-dialog')">
                {{$t('buttons.cancel')}}
              </v-btn>
              <v-btn color="primary" v-on:click="$emit('change-code',newCode)" :disabled="invalid">
                {{$t('buttons.change-code')}}
              </v-btn>
            </v-card-actions>
          </v-container>
        </validation-observer>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  import {ValidationObserver, ValidationProvider, extend} from 'vee-validate';
  import {numeric, required, length} from 'vee-validate/dist/rules';

  extend('required', {
    ...required,
    message: 'The field is required'
  });
  extend('numeric', {
    ...numeric,
    message: 'The field may only contain numeric characters'
  });
  extend('length', {
    ...length,
    message: 'Code length must be 5 numbers'
  });

  export default {
    props: ['open-dialog', 'orderCode', 'prefix'],
    name: "ManualOrderCodeDialog",
    components: {ValidationObserver, ValidationProvider},
    data: () => ({
      newCode: '',
      imgName: 'cloneManagerDialog.jpg'
    }),
  }
</script>

<style scoped>
  .red--text {
    font-size: 12px;
  }
</style>
