<template>
  <v-row justify="center">
    <v-dialog v-model="openDialog" persistent max-width="600px">
      <v-card>
        <v-img src="https://cdn.vuetifyjs.com/images/cards/sunshine.jpg" height="100px"></v-img>
        <v-card-title>
          <span class="headline">{{$t('dialogs.create-manager.create-manager')}}</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <validation-observer ref="observer" v-slot="{ invalid }">
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <validation-provider rules="required" v-slot="{ errors }">
                    <v-text-field
                      v-model="createdManager.surname" :label="$t('dialogs.create-manager.full-name')" clearable/>
                    <span class="red--text font-weight-light">{{ errors[0] }}</span>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field v-model="createdManager.position" :label="$t('dialogs.create-manager.position')" clearable/>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <validation-provider rules="email" v-slot="{ errors }">
                    <v-text-field
                      v-model="createdManager.email" :label="$t('dialogs.create-manager.email')" clearable/>
                    <span class="red--text font-weight-light">{{ errors[0] }}</span>
                  </validation-provider>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="createdManager.mobile" :label="$t('dialogs.create-manager.mobile')" clearable/>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12">
                  <v-textarea v-model="createdManager.comment" class="purple-input" :label="$t('dialogs.create-manager.comments')" clearable counter
                              rows="2"/>
                </v-col>
              </v-row>
            </validation-observer>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text v-on:click="$emit('close-dialog')">
            {{$t('buttons.close')}}
          </v-btn>
          <v-btn color="primary" v-on:click="$emit('save-manager',createdManager)">
            {{$t('buttons.save')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  import {ValidationObserver, ValidationProvider, extend} from 'vee-validate';
  import {email, required} from 'vee-validate/dist/rules';

  extend('required', {
    ...required,
    message: 'The field is required'
  });
  extend('email', {
    ...email,
    message: 'Only valid e-mail address allowed'
  });

  export default {
    props: ['open-dialog', 'agencyId'],
    components: {ValidationProvider, ValidationObserver},
    name: "CreateManagerDialog",
    data: () => ({
      createdManager: {
        surname: '',
        email: '',
        comment: '',
        mobile: '',
        position: '',
      },
    }),
  }
</script>

<style scoped>
  .red--text {
    font-size: 12px;
  }
</style>
