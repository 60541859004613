var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('CreateManagerDialog',{attrs:{"open-dialog":_vm.dialog,"agencyId":_vm.agencyId},on:{"close-dialog":function($event){_vm.dialog=false},"save-manager":function($event){return _vm.saveManager($event)}}}),_c('ManualOrderCodeDialog',{attrs:{"open-dialog":_vm.codeDialog,"orderCode":_vm.orderCode,"prefix":_vm.agency.prefix},on:{"close-dialog":function($event){_vm.codeDialog=false},"change-code":function($event){return _vm.changeCode($event)}}}),_c('v-stepper',{attrs:{"vertical":""},model:{value:(_vm.e6),callback:function ($$v) {_vm.e6=$$v},expression:"e6"}},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',[_c('v-stepper-step',{attrs:{"complete":_vm.e6 > _vm.e6-1,"step":"1"}},[_vm._v(" "+_vm._s(_vm.$t('create-order.main-data'))+" ")]),_c('v-stepper-content',{attrs:{"step":"1"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[_c('v-chip',{attrs:{"label":"","color":"primary","text-color":"white"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-folder-information-outline")]),_vm._v(" "+_vm._s(_vm.$t('create-order.main'))+" ")],1),_c('v-row',{staticClass:"ma-auto"},[_c('v-col',{attrs:{"cols":"12","sm":"4","lg":"4"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.activeAgencies,"label":_vm.$t('create-order.agency'),"item-text":"agencyName","clearable":"","item-value":"agencyId"},on:{"change":_vm.getAgencyById},model:{value:(_vm.agencyId),callback:function ($$v) {_vm.agencyId=$$v},expression:"agencyId"}}),_c('span',{staticClass:"red--text font-weight-light"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","lg":"4"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.langPairs,"label":_vm.$t('create-order.language'),"required":"","item-text":"fullPairName","item-value":"langPairId","multiple":"","clearable":""},model:{value:(_vm.langPairIds),callback:function ($$v) {_vm.langPairIds=$$v},expression:"langPairIds"}}),_c('span',{staticClass:"red--text font-weight-light"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","lg":"4"}},[_c('v-text-field',{attrs:{"label":_vm.$t('create-order.incoming-number'),"clearable":"","hint":_vm.$t('create-order.enter-order-number-hint')},model:{value:(_vm.incomingNumber),callback:function ($$v) {_vm.incomingNumber=$$v},expression:"incomingNumber"}})],1)],1)],1)],1),_c('StepperContinueButton',{attrs:{"disabled":invalid},on:{"next-step":function($event){_vm.e6=++_vm.e6}}})],1)],1)]}}])}),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',[_c('v-stepper-step',{attrs:{"complete":_vm.e6 > 2,"step":"2"}},[_vm._v(" "+_vm._s(_vm.$t('create-order.describe-order-details'))+" ")]),_c('v-stepper-content',{attrs:{"step":"2"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[_c('v-chip',{attrs:{"label":"","color":"primary","text-color":"white"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-card-text-outline ")]),_vm._v(" "+_vm._s(_vm.$t('create-order.detailed-description'))+" ")],1),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{staticClass:"purple-input",attrs:{"label":_vm.$t('create-order.order-description'),"clearable":"","counter":"","rows":"3"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_c('span',{staticClass:"red--text font-weight-light"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"sm":"6","md":"6"}},[_c('StepperBackButton',{on:{"back-step":function($event){_vm.e6=--_vm.e6}}})],1),_c('v-col',{attrs:{"sm":"6","md":"6"}},[_c('StepperContinueButton',{attrs:{"disabled":invalid},on:{"next-step":function($event){_vm.e6=++_vm.e6}}})],1)],1)],1)],1)]}}])}),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',[_c('v-stepper-step',{attrs:{"complete":_vm.e6 > 3,"step":"3"}},[_vm._v(" "+_vm._s(_vm.$t('create-order.define-dates'))+" ")]),_c('v-stepper-content',{attrs:{"step":"3"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[_c('v-chip',{attrs:{"label":"","color":"primary","text-color":"white"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-calendar-range ")]),_vm._v(" "+_vm._s(_vm.$t('create-order.dates'))+" ")],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-datetime-picker',{attrs:{"label":_vm.$t('create-order.order-date'),"timePickerProps":_vm.timePickerProps,"date-picker-props":_vm.datePickerProps,"text-field-props":_vm.textFieldProps},model:{value:(_vm.orderDate),callback:function ($$v) {_vm.orderDate=$$v},expression:"orderDate"}}),_c('span',{staticClass:"red--text font-weight-light"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-datetime-picker',{attrs:{"label":_vm.$t('create-order.finish-time'),"timePickerProps":_vm.timePickerProps,"date-picker-props":_vm.datePickerProps,"text-field-props":_vm.textFieldProps},model:{value:(_vm.finishTime),callback:function ($$v) {_vm.finishTime=$$v},expression:"finishTime"}}),_c('span',{staticClass:"red--text font-weight-light"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1),(_vm.finishTime!=='' && _vm.orderDate>_vm.finishTime)?_c('v-alert',{attrs:{"dense":"","outlined":"","border":"left","text":"","type":"error"}},[_c('strong',[_vm._v(_vm._s(_vm.$t('create-order.warning'))+": ")]),_vm._v(_vm._s(_vm.$t('create-order.dates-warning-text'))+" ")]):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"sm":"6","md":"6"}},[_c('StepperBackButton',{on:{"back-step":function($event){_vm.e6=--_vm.e6}}})],1),_c('v-col',{attrs:{"sm":"6","md":"6"}},[_c('StepperContinueButton',{attrs:{"disabled":invalid || _vm.finishTime!=='' && _vm.orderDate>_vm.finishTime},on:{"next-step":function($event){_vm.e6=++_vm.e6}}})],1)],1)],1)],1)]}}])}),_c('v-stepper-step',{attrs:{"step":"4","complete":_vm.e6 > 4}},[_vm._v(" "+_vm._s(_vm.$t('create-order.statistics'))+" ")]),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',[_c('v-stepper-content',{attrs:{"step":"4"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[_c('v-chip',{attrs:{"label":"","color":"primary","text-color":"white"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-finance ")]),_vm._v(" "+_vm._s(_vm.$t('create-order.statistics'))+" ")],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3","sm":"6"}},[_c('validation-provider',{attrs:{"rules":{required:true,numeric:true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('create-order.symbol-count')},on:{"input":_vm.recalculate},model:{value:(_vm.symbolCount),callback:function ($$v) {_vm.symbolCount=$$v},expression:"symbolCount"}}),_c('span',{staticClass:"red--text font-weight-light"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"3","sm":"6"}},[_c('validation-provider',{attrs:{"rules":{required:true,numeric:true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('create-order.price-per-page'),"readonly":_vm.readonly},on:{"input":_vm.recalculate},model:{value:(_vm.pricePerPage),callback:function ($$v) {_vm.pricePerPage=$$v},expression:"pricePerPage"}}),_c('span',{staticClass:"red--text font-weight-light"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"3","sm":"6"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('create-order.page-count')},on:{"input":_vm.recalculate},model:{value:(_vm.pageCount),callback:function ($$v) {_vm.pageCount=$$v},expression:"pageCount"}}),_c('span',{staticClass:"red--text font-weight-light"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"3","sm":"6"}},[_c('validation-provider',{attrs:{"rules":{required:true,numeric:true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('create-order.total-cost'),"readonly":_vm.readonly},on:{"input":_vm.recalculate},model:{value:(_vm.totalCost),callback:function ($$v) {_vm.totalCost=$$v},expression:"totalCost"}}),_c('span',{staticClass:"red--text font-weight-light"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-autocomplete',{attrs:{"items":_vm.calculationTypes,"label":_vm.$t('create-order.calculation-type'),"required":"","item-text":"calculationTypeName","item-value":"calculationTypeId","readonly":_vm.readonly},on:{"change":_vm.changeCalculation},model:{value:(_vm.calculationTypeId),callback:function ($$v) {_vm.calculationTypeId=$$v},expression:"calculationTypeId"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-switch',{attrs:{"inset":"","label":_vm.$t('create-order.free-order')},on:{"change":_vm.isFreeOrderCheck},model:{value:(_vm.freeOrder),callback:function ($$v) {_vm.freeOrder=$$v},expression:"freeOrder"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-switch',{attrs:{"inset":"","disabled":_vm.readonly,"label":_vm.userSettings.minOrderEnabled? _vm.$t('create-order.min-order-enabled') :_vm.$t('create-order.min-order-enabled')},on:{"change":_vm.recalculate},model:{value:(_vm.userSettings.minOrderEnabled),callback:function ($$v) {_vm.$set(_vm.userSettings, "minOrderEnabled", $$v)},expression:"userSettings.minOrderEnabled"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-expand-x-transition',[(_vm.wppIndex!==null)?_c('div',[_c('validation-provider',{attrs:{"rules":{required:true,numeric:true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('create-order.word-count'),"readonly":_vm.readonly},on:{"input":_vm.recalculate},model:{value:(_vm.wordCount),callback:function ($$v) {_vm.wordCount=$$v},expression:"wordCount"}}),_c('span',{staticClass:"red--text font-weight-light"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e()])],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"sm":"6","md":"6"}},[_c('StepperBackButton',{on:{"back-step":function($event){_vm.e6=--_vm.e6}}})],1),_c('v-col',{attrs:{"sm":"6","md":"6"}},[_c('StepperContinueButton',{attrs:{"disabled":invalid},on:{"next-step":function($event){_vm.e6=++_vm.e6}}})],1)],1)],1)],1)]}}])}),_c('v-stepper-step',{attrs:{"step":"5","complete":_vm.e6 > 5}},[_vm._v(" "+_vm._s(_vm.$t('create-order.manager'))+" ")]),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',[_c('v-stepper-content',{attrs:{"step":"5"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[_c('v-chip',{attrs:{"label":"","color":"primary","text-color":"white"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-account-details ")]),_vm._v(" "+_vm._s(_vm.$t('create-order.manager'))+" ")],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8","sm":"8"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.filteredManagers,"label":_vm.$t('create-order.manager'),"required":"","item-text":"surname","item-value":"managerId"},model:{value:(_vm.managerId),callback:function ($$v) {_vm.managerId=$$v},expression:"managerId"}}),_c('span',{staticClass:"red--text font-weight-light"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"4"}},[(_vm.agencyId!=='')?_c('v-btn',{attrs:{"color":"secondary","block":""},on:{"click":function($event){_vm.dialog = true}}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" mdi-account-plus-outline ")]),_vm._v(" "+_vm._s(_vm.$t('buttons.create-manager'))+" ")],1):_vm._e()],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"sm":"6","md":"6"}},[_c('StepperBackButton',{on:{"back-step":function($event){_vm.e6=--_vm.e6}}})],1),_c('v-col',{attrs:{"sm":"6","md":"6"}},[_c('StepperContinueButton',{attrs:{"disabled":invalid},on:{"next-step":function($event){_vm.e6=++_vm.e6}}})],1)],1)],1)],1)]}}])}),_c('v-stepper-step',{attrs:{"step":"6","complete":_vm.e6 > 6}},[_vm._v(" "+_vm._s(_vm.$t('create-order.extras'))+" "),_c('small',[_vm._v(_vm._s(_vm.$t('create-order.additional-info')))])]),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',[_c('v-stepper-content',{attrs:{"step":"6"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[_c('v-chip',{attrs:{"label":"","color":"primary","text-color":"white"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-information-outline ")]),_vm._v(" "+_vm._s(_vm.$t('create-order.extras'))+" ")],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3","sm":"6"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.orderTypes,"label":_vm.$t('create-order.order-type'),"required":"","item-text":"orderTypeName","item-value":"orderTypeId"},model:{value:(_vm.orderTypeId),callback:function ($$v) {_vm.orderTypeId=$$v},expression:"orderTypeId"}}),_c('span',{staticClass:"red--text font-weight-light"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"3","sm":"6"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.topics,"label":_vm.$t('create-order.topic'),"required":"","item-text":"topicName","item-value":"topicId","multiple":""},model:{value:(_vm.topicIds),callback:function ($$v) {_vm.topicIds=$$v},expression:"topicIds"}}),_c('span',{staticClass:"red--text font-weight-light"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"3","sm":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$t('create-order.customer'),"clearable":""},model:{value:(_vm.customerName),callback:function ($$v) {_vm.customerName=$$v},expression:"customerName"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3","sm":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.catTools,"label":_vm.$t('create-order.cat-tool'),"required":"","item-text":"toolName","item-value":"toolId","multiple":""},model:{value:(_vm.toolIds),callback:function ($$v) {_vm.toolIds=$$v},expression:"toolIds"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"9","sm":"9"}},[_c('v-textarea',{staticClass:"purple-input",attrs:{"label":_vm.$t('create-order.comments'),"clearable":"","counter":"","rows":"2"},model:{value:(_vm.comments),callback:function ($$v) {_vm.comments=$$v},expression:"comments"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3","sm":"3"}},[_c('v-btn',{attrs:{"color":"secondary"},on:{"click":function($event){_vm.codeDialog = true}}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" mdi-qrcode-edit")]),_vm._v(" "+_vm._s(_vm.$t('buttons.change-order-code'))+" ")],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"sm":"6","md":"6"}},[_c('StepperBackButton',{on:{"back-step":function($event){_vm.e6=--_vm.e6}}})],1),_c('v-col',{attrs:{"sm":"6","md":"6"}},[_c('v-btn',{attrs:{"loading":_vm.loading,"color":"primary","disabled":invalid,"block":""},on:{"click":_vm.createOrder}},[_vm._v(" "+_vm._s(_vm.$t('buttons.create-order'))+" ")])],1)],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }